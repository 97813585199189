import { CContainer, CRow, CCol } from '@coreui/react';
import { t } from 'i18next';
import React from 'react';
import Waves from 'shared/Components/icons/Waves';
import { handleLinkClick } from 'shared/service/CommonService';

type AuthLayoutProps = {
    children: React.ReactNode;
    heading?: string;
    subheading?: string;
};
const AuthLayout = (props: AuthLayoutProps) => {
    return (
        <div className="c-app c-default-layout">
            <img src={require('assets/images/splash.png')} className="auth-bg-img" alt={t('not_avaialble')} />
            <div className="position-absolute w-100 mt-3" style={{ opacity: '0.5', zIndex: '9' }}>
                <Waves />
            </div>
            <CContainer fluid>
                <CRow className="d-flex h-100">
                    <CCol
                        md="5"
                        className="bg-background-01 d-flex flex-column justify-content-between align-items-start p-5"
                    >
                        <div style={{ zIndex: '100' }}>
                            <img
                                src={require('assets/images/logo_2.png')}
                                className="w-25 cursor-pointer"
                                alt={t('not_avaialble')}
                                onClick={() => handleLinkClick('https://metalmark.xyz/')}
                            />
                        </div>

                        <div
                            className="d-flex justify-content-start flex-column text-white w-100"
                            style={{ zIndex: '99' }}
                        >
                            <div>
                                <div className="h1 line-height-16 font-weight-bolder mb-3 text-uppercase">
                                    {props.heading}
                                </div>
                                <div className="font-weight-3 font-weight-normal">
                                    {props.subheading}
                                    <div className="w-30">
                                        <hr className="login-bottom-bar w-100" />
                                    </div>
                                </div>
                            </div>
                            {props.children}
                        </div>
                    </CCol>
                    <CCol md="7" className="h1 font-weight-bolder text-white d-flex align-items-end text-uppercase">
                        <div className="d-flex flex-column w-100">
                            <div className="mb-2 d-flex justify-content-center">
                                <div>{t('make_your')}</div>&nbsp;
                                <div className="auth-text-color">{t('indoor_air')}&nbsp;</div>
                                {t('reliably')}&nbsp;
                                <div className="auth-text-color">{t('clean')}</div>
                            </div>
                            <div className="font-base-bold d-flex justify-content-end mb-4 mt-4 pt-2 pr-3">
                                {t('for_use_with_tatama')}
                                <sup className="font-base">{t('tm')}</sup>
                            </div>
                        </div>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    );
};

export default AuthLayout;
