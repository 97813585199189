import dagre from 'dagre';
import { isNode, Position } from 'react-flow-renderer';
import { IDeviceData } from 'shared/model';
import { NodeElement } from 'shared/model/SiteModel';

const nodeWidth = 280;
const nodeHeight = 220;

export const getLayoutedElements = (nodes: any, edges: any, direction = 'TB') => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    dagreGraph.setGraph({ rankdir: direction, ranksep: 200, nodesep: 200 });
    nodes.forEach((node: any) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge: any) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.map((el: any) => {
        if (isNode(el)) {
            const nodeWithPosition = dagreGraph.node(el.id);
            el.targetPosition = Position.Top;
            el.sourcePosition = Position.Bottom;

            // unfortunately we need this little hack to pass a slighltiy different position
            // to notify react flow about the change. More over we are shifting the dagre node position
            // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
            let displayLevel = -1;
            if (el.data?.nodeObj?.details?.display_level != undefined) {
                displayLevel = el.data?.nodeObj?.details?.display_level;
            }
            el.position = {
                x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
                y: nodeWithPosition.y - nodeHeight / 2 + displayLevel * 500 + nodeHeight * 2,
            };
        }

        return el;
    });
    return { nodes, edges };
};

export const getChildId = (nodes: NodeElement[], parent_id: any, arayOfHiddingId: Array<any>) => {
    const childNodes = nodes.filter((node: NodeElement) => node.parent_id !== node.id && node.parent_id === parent_id);
    if (childNodes?.length >= 0) {
        arayOfHiddingId.push(...childNodes.map((node: NodeElement) => node.id));
        childNodes.forEach((node: NodeElement) => getChildId(nodes, node.id, arayOfHiddingId));
    } else {
        return arayOfHiddingId;
    }
    return arayOfHiddingId;
};

export const getChildStatusTotal = (nodes: NodeElement[], node: NodeElement) => {
    const arrofChildId = getChildId(nodes, node.id, []);
    if (arrofChildId.length > 0) {
        const childs = nodes.filter((node: NodeElement) => arrofChildId.some((val: any) => val === node.id));
        if (childs.length > 0) {
            return childs.reduce(
                (obj: IDeviceData, currentChild: NodeElement) => {
                    if (currentChild.data?.device_summary && node.data) {
                        if (currentChild.data?.device_summary.Hazardous) {
                            obj.Hazardous = obj.Hazardous + currentChild.data?.device_summary.Hazardous;
                        }
                        if (currentChild.data?.device_summary.Healthy) {
                            obj.Healthy = obj.Healthy + currentChild.data?.device_summary.Healthy;
                        }
                        if (currentChild.data?.device_summary.Moderate) {
                            obj.Moderate = obj.Moderate + currentChild.data?.device_summary.Moderate;
                        }
                        if (currentChild.data?.device_summary.Ideal) {
                            obj.Ideal = obj.Ideal + currentChild.data?.device_summary.Ideal;
                        }
                        if (currentChild.data?.device_summary.offline) {
                            obj.offline = obj.offline + currentChild.data?.device_summary.offline;
                        }
                        if (currentChild.data?.device_summary.DataError) {
                            obj.DataError = obj.DataError + currentChild.data?.device_summary.DataError;
                        }
                        if (currentChild.data?.device_summary.total) {
                            obj.total = obj.total + currentChild.data?.device_summary.total;
                        }
                    }
                    return obj;
                },
                {
                    Hazardous: 0,
                    Healthy: 0,
                    Moderate: 0,
                    offline: 0,
                    Ideal: 0,
                    online: 0,
                    total: 0,
                    available_devices: 0,
                    empty: 0,
                    DataError: 0,
                },
            );
        }
    }
};
